<template>
  <div class="container">
    <div class="main">
      <div class="card-container info-container">
        <el-row type="flex">
          <el-avatar :size="100" icon="el-icon-user-solid" :src="detail.user.avatarImg"
                     @click.native="clickChatToProvider"></el-avatar>
          <div class="info">
            <div class="price">
              <div class="label">任务金额</div>
              <div class="number-container">
                <span class="symbol">￥</span>
                <span class="number">{{ detail.amount }}</span>
              </div>
            </div>
            <div class="title">{{ detail.title }}</div>
            <div class="time-container">
              
              <i class="el-icon-time"></i><span>距结束时间：</span>
              <countdown-timer :end-time="detail.expirationDate" expiredContent="已结束" />
            </div>
          </div>
        </el-row>
      </div>
      <el-row class="step">
        <el-steps :active="detail.step" align-center>
          <el-step icon="el-icon-arrow-right" title="发布需求"></el-step>
          <el-step icon="el-icon-arrow-right" title="支付"></el-step>
          <el-step icon="el-icon-arrow-right"
                   :title="detail.requirementState === NEED_STATE.CANCELED.value ? '已取消' : '抢单'"></el-step>
          <el-step icon="el-icon-arrow-right" title="雇用"></el-step>
          <el-step icon="el-icon-arrow-right" title="工作开始"></el-step>
          <el-step icon="el-icon-arrow-right" title="工作完成"></el-step>
          <el-step icon="el-icon-arrow-right" title="验收"></el-step>
          <el-step icon="el-icon-arrow-right" title="评价"></el-step>
        </el-steps>
      </el-row>
      <div class="card-container required-container">
        <div class="label-container">
          <span class="label">任务需求</span>
        </div>
        <div class="content">{{ detail.requirementParticulars }}</div>
        <!--<ul>-->
        <!--  <li>小红书软文撰写+图片设计；</li>-->
        <!--  <li>公众号主题撰写+文章撰写排版；</li>-->
        <!--</ul>-->
      </div>
      <div class="card-container required-container">
        <div class="label-container">
          <span class="label">任务图片</span>
        </div>
        <div class="content">
          <el-carousel :interval="4000" :autoplay="false" :loop="false" type="card" arrow="always">
            <el-carousel-item v-for="(item, index) of detail.images" :key="index">
              <el-image style="width:100%; height: 100%"
                        :src="item" fit="contain"
                        :preview-src-list="detail.images"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!--
      <div class="card-container desc-container">
        <div class="label-container">
          <span class="label">稿件说明</span>
        </div>
        <el-input
            class="textarea"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 10}"
            placeholder="请输入稿件说明，要求5-1500字之间"
            maxlength="1500"
            minlength="5"
            show-word-limit
            v-model="desc">
        </el-input>
        <div class="upload">
          <draggable v-model="uploadlist" class="pic-upload-container">
            <image-upload v-for="(item, index) in uploadlist" :key="index" v-model="item.img" :fileType="fileType"
                          :limit="1" :isShowTip="false"/>
          </draggable>
        </div>
        <div class="button-container">
          <el-button type="primary">提交稿件</el-button>
        </div>
      </div>
      -->
      <div class="card-container" v-if="detail.grabUser">
        <div class="label-container">
          <span class="label">服务商信息</span>
        </div>
        <Provider v-if="detail.grabUser" :id="detail.grabUser.id" ref="provider"></Provider>
      
      
      </div>
      <el-row class="provider-item last" type="flex" justify="space-between" v-if="detail.grabUser">
        <span class="submit-time">提交于：{{ moment(detail.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        <div class="button-container" v-if="isPoster">
          <!--<span class="status">雇主已浏览</span>-->
          <el-button size="mini" @click="clickChat">联系TA</el-button>
          <el-button size="mini" @click="clickReject">驳回抢单</el-button>
          <el-button size="mini" @click="clickSubmit">确认雇用</el-button>
        </div>
      </el-row>
    </div>
    
    <!-- region 右上角抢单 -->
    <div class="stick-container" :style="'top: ' + stickyHeight">
      <div class="view-sign-container">
        <div class="view-container item-container">
          <div class="label">浏览人数</div>
          <div class="number">{{ detail.viewCount || 0 }}</div>
        </div>
      </div>
      <el-divider v-if="detail.requirementState === NEED_STATE.GRABBING_ORDER.value"></el-divider>
      <div class="item button-container" v-if="detail.requirementState === NEED_STATE.GRABBING_ORDER.value">
        <el-button class="submit" @click="clickGrab">抢单</el-button>
      </div>
    </div>
    <!-- endregion 右上角抢单 -->
    
    <!-- region 抢单弹窗 -->
    <el-dialog :visible.sync="showSignDialog" center width="20%"
               :close-on-click-modal="false"
    >
      <div class="dialog-info success"><i class="el-icon-check"></i></div>
      <div class="dialog-info content">恭喜您，任务已抢单成功！</div>
      <div class="dialog-info content">任务已收藏，可进入收藏任务查看，别忘记在 <span
          class="tips">截稿日（2023-8-23）</span>前提交稿件哦~
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="clickCollection">查看收藏</el-button>
          <el-button type="primary" @click="showSignDialog = false">关闭页面</el-button>
        </span>
    </el-dialog>
    <!-- endregion 抢单弹窗 -->
    
    <!-- region 稿件弹窗 -->
    <el-dialog :visible.sync="post.show" width="40%"
               title="稿件说明"
               :close-on-click-modal="false"
               custom-class="dialog-post"
    >
      <div class="content">{{ post.content }}</div>
      <el-carousel :interval="4000" :autoplay="false" :loop="false" type="card" arrow="always">
        <el-carousel-item v-for="(item, index) of post.images" :key="index">
          <el-image style="width:100%; height: 100%"
                    :src="item" fit="contain"
                    :preview-src-list="post.images"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <!-- endregion 稿件弹窗 -->
  </div>

</template>
<script>

//  list 后续做 loading 加载
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
import moment from "moment";
// import draggable from "vuedraggable";
import Provider from "@/views/Service/Home.vue";
import { REQUIREMENT_STATE } from "@/constants/requirement";

export default {
  data() {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      endTime: +moment().add(2, 'day'),
      activeStep: 1,
      desc: '',
      dialogImageUrl: '',
      dialogVisible: false,
      tags: ['完成', '售后', '原创'],
      fileType: ["png", "jpg", "jpeg", "bmp", "gif"],
      uploadlist: [
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
      ],
      showSignDialog: false,
      post:       {
        show: false,
        content: '我心目中的数字人是千变万化的，除了平时固有的相态外，应该附带千变万化形态符合大众之审美，\n' +
            '现如今更行各业的需求都不同，需求的数字主播带货，才艺直播，各种直播短视频都需要用到不同\n' +
            '形态的数字人都颇多，目前的我这款数字人形态，是参赛中及不同大众想象中的亿万形态中的普通\n' +
            '的一款形态，希望大家能喜欢，谢谢。',
        images: [
          require('@/assets/imgs/huodong/icon1.png'),
          require('@/assets/imgs/huodong/icon2.png'),
          require('@/assets/imgs/huodong/icon3.png'),
          require('@/assets/imgs/huodong/icon4.png'),
          require('@/assets/imgs/avter.jpg'),
        ]
      },
      rspData:    {},
      NEED_STATE: REQUIREMENT_STATE,
      id:         0,
    };
  },
  components: {
    // draggable,
    CountdownTimer,
    Provider,
  },
  created() {
    this.id = +this.$route.query.id;
    this.getDetail()
  },
  mounted() {
  
  },
  methods: {
    moment,
    getDetail() {
      this.$axios
          .get(`/app-api/hiring/requirement/get`, {
            params: {
              id: this.id
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.rspData = res.data.data
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    clickCollection() {
      this.showSignDialog = false
      this.$router.push({
        name: 'DemandCollectionList'
      })
    },
    clickGrab() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      this.$axios
          .post(`/app-api/hiring/requirement/grab`, {
            id: this.id,
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success('抢单成功')
              // 进入任务订单列表
              this.$router.push({
                name: 'DemandCollectionList',
              })
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },
    clickChat() {
      this.$refs.provider.clickShowChat()
    },
    clickReject() {
      let _this = this
      _this.$confirm("确定驳回抢单吗？")
          .then(() => {
            _this.$axios
                .post(`/app-api/hiring/requirement/grab-reject`, {
                  id: _this.id,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    _this.$notify.success("驳回抢单成功！")
                    _this.getDetail()
                  }
                })
                .catch(() => _this.$notify.error("驳回抢单失败！"))
          })
          .catch(() => {
            _this.$notify.info('已取消驳回抢单');
          });
    },
    clickSubmit() {
      let _this = this
      _this.$confirm("确认雇用吗？")
          .then(() => {
            _this.$axios
                .post(`/app-api/hiring/requirement/grab-accept`, {
                  id: _this.id,
                })
                .then((res) => {
                  if (res.data.code === 0) {
                    _this.$notify.success("雇用成功！")
                    _this.$router.push({name: 'PublishedDemandList'})
                  }
                })
                .catch(() => _this.$notify.error("雇用失败！"))
          })
          .catch(() => {
            _this.$notify.info('已取消操作');
          });
    },
    clickChatToProvider() {
      if (this.detail.user.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: this.detail.user.userPhone,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
  },
  computed: {
    stickyHeight() {
      return this.$store.getters.getStickyHeight
    },
    isPoster() {
      return this.rspData.user.id === this.$store.getters.getUser.id
    },
    detail() {
      if (!this.rspData) return
      let ret = this.rspData;
      if (ret.filePath) ret.images = ret.filePath.split(',')
      // if (ret.requirementState === this.NEED_STATE.CANCELED.value) ret.step = 2
      // else ret.step = +ret.requirementState
      // 当前时间  + 3小时 - 支付时间
      if (!ret.expirationDate) ret.expirationDate = +moment(ret.createTime).add(3, 'hours')
      let key = Object.keys(this.NEED_STATE).find(key => +this.NEED_STATE[key].value === ret.requirementState)
      if (key) {
        ret.step = this.NEED_STATE[key].step
      }
      
      return ret
    }
  }
};
</script>
<style lang="less">
@main-color: #FC3737;
@process-color: #747479;
.step {
  margin-top: -9px;
}

.el-step__icon.is-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  font-size: 12px;
  background-color: #c0c4cc;
  
  .el-step__icon-inner {
    color: #ffffff;
    font-size: 14px;
  }
}

.is-finish {
  .el-step__icon.is-icon {
    background-color: @main-color;
  }
}

.is-process {
  .el-step__icon.is-icon {
    background-color: @process-color;
  }
}

.el-step.is-center .el-step__line {
  left: 0;
  right: 0;
}

.el-step:last-of-type .el-step__line {
  display: block;
}

.is-finish .el-step__line {
  background-color: @main-color;
}

.is-process .el-step__line {
  background-color: @process-color;
}

.el-step__title.is-process {
  color: @process-color
}

.el-dialog__header {
  i {
    font-size: 26px;
  }
}

.dialog-post {
  .el-dialog__body {
    padding: 20px 30px;
  }
  
  .content {
    border: 1px solid #D9D9D9;
    padding: 16px;
    margin-bottom: 20px;
  }
}

</style>
<style lang="less" scoped>
@main-color: #FC3737;
.el-avatar {
  cursor: pointer;
}

.container {
  display: flex;
}

.main {
  margin: 0 auto;
  flex: 1;
}

.card-container {
  background-color: #ffffff;
  padding: 30px 40px;
  
  & + .card-container {
    margin-top: 20px;
  }
  
  .label-container {
    position: relative;
    padding-bottom: 20px;
    
    &::before {
      position: absolute;
      content: '';
      background-color: @main-color;
      width: 3px;
      height: 16px;
      top: 5px;
    }
  }
  
  .label {
    font-size: 18px;
    font-weight: 600;
    padding-left: 10px;
  }
}

.info-container {
  padding-bottom: 30px;
  
  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    
    .price {
      display: flex;
      
      .label, .number-container {
        border: 1px @main-color solid;
        font-size: 14px;
        height: 26px;
        line-height: 26px;
        color: @main-color;
        min-width: 80px;
        text-align: center;
      }
      
      .label {
        background-color: @main-color;
        color: #ffffff;
      }
      
      .number-container {
        font-size: 12px;
        
        .number {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    
    .title {
      font-size: 18px;
      font-weight: 600;
      margin-top: 16px;
    }
    
    .time-container {
      display: flex;
      align-items: center;
      margin-top: 16px;
      color: @main-color;
      font-size: 14px;
      
      i {
        font-size: 18px;
      }
      
      span {
        color: #555555;
        margin-left: 5px;
      }
    }
  }
}

.required-container {
  margin-top: 30px;
  
  ul {
    margin: auto 30px;
    
    li {
      list-style: decimal;
      
      & + li {
        margin-top: 8px;
      }
    }
  }
}

.desc-container {
  .textarea {
    margin-top: 10px;
  }
  
  .pic-upload-container {
    display: flex;
    margin-top: 10px;
    
    .component-upload-image {
      & + .component-upload-image {
        margin-left: 8px;
      }
      
      .el-upload-list__item {
        margin: 0;
      }
    }
  }
  
  .button-container {
    margin-top: 10px;
  }
}

.people-container {
  background-color: #ffffff;
  padding: 0;
  
  &.card-container {
    padding: 30px 40px 0 40px;
  }
  
  & + .people-container {
    margin-top: 20px;
  }
  
  .provider-item {
    display: flex;
    padding: 20px 40px;
    
    &:not(:last-of-type) {
      border-bottom: 1px solid #D9D9D9;
    }
    
    .provider-info {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    
    .provider-info-row {
      display: flex;
      align-items: center;
      
      .el-tag {
        margin-left: 5px;
        border: none;
        
        &.tag {
          background-color: #FFB40E;
        }
        
        &.type {
          background-color: #F46700;
        }
        
        &.money {
          background-color: #36A04C;
        }
      }
    }
    
    .provider-item-container {
      display: flex;
      justify-content: center;
      color: @main-color;
      font-size: 18px;
    }
    
    .price-container {
      display: flex;
      align-items: baseline;
      color: @main-color;
      font-size: 12px;
      
      .number {
        font-size: 18px;
        font-weight: 600;
      }
    }
    
    .label {
      margin-top: 20px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #ABABAB;
      color: #ffffff;
      
      &.blue {
        background-color: #3D8BFF;
      }
    }
  }
}

.submit-time {
  color: #999999;
  font-size: 12px;
}

.provider-item.last {
  align-items: center;
  background-color: #ffffff;
  border-top: 1px solid #D9D9D9;
  padding: 20px 40px 50px;
}

.button-container {
  .status {
    color: #3D8BFF;
    font-size: 12px;
    margin-right: 20px;
  }
}

.card-container .ServiceHome {
  margin: 0;
  padding: 0;
}

.stick-container {
  position: sticky;
  align-self: flex-start;
  z-index: 99;
  margin-left: 20px;
  padding: 30px 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 4px 1px #e2eef4;
  
  .view-sign-container {
    display: flex;
    
    .item-container {
      text-align: center;
      width: 200px;
      height: 60px;
      
      .label {
        font-size: 14px;
      }
      
      .number {
        font-weight: 600;
        margin-top: 10px;
      }
    }
  }
  
  .el-divider--vertical {
    height: 60px;
  }
  
  .el-divider--horizontal {
    margin: 0;
  }
  
  .button-container {
    margin-top: 40px;
    
    button.submit {
      width: 100%;
      background-color: #FC3737;
      border-radius: 3px;
      color: #ffffff;
      cursor: pointer;
      
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
}

// 报名成名弹窗
.dialog-info {
  text-align: center;
  
  i {
    font-size: 80px;
    font-weight: 600;
    color: #FC3737;
  }
  
  &.content {
    font-size: 16px;
    font-weight: 600;
    
    .tips {
      color: #FC3737;
    }
  }
  
  & + .dialog-info {
    margin-top: 20px;
  }
}


</style>
